import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Maindeportes from "../components/maindeportes"
import Topicdeportes from "../components/topicdeportes"
import Notesdeportes from "../components/notesdeportes"

const DeportesPage = () => (
  <>
    <SEO title="Futbol Internacional" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <div className="grid-container-templatenota">
      <div className="templatenota-banner-section"></div>
      <div>
        <Maindeportes />
        <Topicdeportes />
        <Notesdeportes />
      </div>
      <div className="templatenota-banner-section"></div>
    </div>
  </>
)

export default DeportesPage
